import React from "react"

import Layout from "../layouts/layout"
import Seo from "../components/seo"
import {css} from "styled-components"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div
      css={css`
        position: relative;
        max-width: 960px;
        margin: auto;
        padding: 40px 0px;
      `}
    >
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
